import React from 'react'
import QC from '../components/qc'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const App = ({data}) => {
  return(
    <Layout>
    <Router>
      <QC path="/qc/:id" data={data} />
    </Router>
    </Layout>
  )
}

export default App


export const query = graphql`
query ProductQuery {
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          product_name
          configurator_id
          pricing_options {
            description
            model
            weight
            base_configuration
            net_price {
              currency
              value
            }
            smart_codes_selected {
              position
              smart_code
            }
          }
        }
      }
    }
  }
}
`
