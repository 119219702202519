import React, { Component } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

class SelectObject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initOption: {...this.props.option},
      colorPicker: null,
      pickerMetaIndex: 0
    }
  }
  componentDidMount(){

  }

  componentDidUpdate(prevProps) {

  }

  onTextChange = (event) => {
    let selectedValue = this.props.option.children.find(element=>element.id === this.props.option.selected)
    this.props.option.customDesc = {
      index: selectedValue.showColorPicker,
      value: event.target.value
    }
    let previousOption = {...this.state.initOption}
    this.setState({
      initOption: {...this.props.option}
    }, function(){
      this.props.updateData(this.props.option, previousOption, this.props.root)
    })
  }

  onSelect = (event) =>{
    this.props.option.selected = event.target.value
    let previousOption = {...this.state.initOption}
    let colorPicker = null
    let pickerMetaIndex = 0
    let customDesc = null
    if(event.target.value.showColorPicker){
      colorPicker = <input type="text" placeholder="ENTER RAL COLOR VALUE" onChange={this.onTextChange} />
      pickerMetaIndex = event.target.value.showColorPicker
      customDesc = {
        index: event.target.value.showColorPicker,
        value: ''
      }
    }
    this.props.option.customDesc = customDesc
    this.setState({
      initOption: {...this.props.option},
      colorPicker: colorPicker,
      pickerMetaIndex: pickerMetaIndex
    }, function(){
      this.props.updateData(this.props.option, previousOption, this.props.root)
    })
  }

  render() {
    let selectObjects = null
    let subOptions = []
    let availableOptions = 0

    if(this.props.option.children.length){
      selectObjects = this.props.option.children.map(function(option, i){
        if(option.selected){
          if(option.children.length){
            subOptions.push(option)
          }
        }
        if(!option.disabled){
          availableOptions++
          let image = null
          if(option.swatchColour){
            let colorSum = option.swatchColour.red + option.swatchColour.green + option.swatchColour.blue
            if(colorSum){
              let colorStyle = 'rgba('+option.swatchColour.red+', '+option.swatchColour.green+', '+option.swatchColour.blue+', '+option.swatchColour.alpha+')'
              image = <div className="colorBubble" style={{
                'backgroundColor': colorStyle
              }} />
            }
          }
          return(<MenuItem value={option.id} key={i}>{image}{option.label}</MenuItem>)
        }else{
          return(null)
        }
      })
    }

    let value = ''
    let smartPart = []
    let metaDesc = []
    let colorPicker = this.state.colorPicker
    if(this.props.option.selected && availableOptions > 0){
      value = this.props.option.selected
      let selectedValue = this.props.option.children.find(element=>element.id === this.props.option.selected)
      Object.entries(selectedValue.metadata).forEach(([key, value]) => {
        let valuesPlacement = key.split('_')
        if(valuesPlacement[0] === 'smartpart'){
          smartPart[parseInt(valuesPlacement[1])] = value
        }else if(valuesPlacement[0] === 'smartdesclabel'){

        }else if(valuesPlacement[0] === 'smartdesc'){
          metaDesc[parseInt(valuesPlacement[1])] = value
        }
      })
      if(selectedValue.showColorPicker){
        colorPicker = <input type="text" placeholder="ENTER RAL COLOR" onChange={this.onTextChange} />
      }
    }else{
      this.props.option.omit = true
    }
    if(selectObjects){
      // selectObjects = <select value={value} onChange={this.onSelect}>{selectObjects}</select>
      selectObjects = <Select value={value} onChange={this.onSelect}>
        {selectObjects}
        </Select>
    }

    let selectedClass = (this.props.selected ? 'selected' : '')
    if(this.props.option.hide || availableOptions === 0){
      selectedClass = ''
    }

    let metaDesc1 = ''
    if(this.props.metaDesc.length){
      if(this.props.metaDesc[this.props.index]){
        let metaVal = this.props.metaDesc[this.props.index].value
        if(this.props.customDesc[this.props.index]){
          metaVal = metaVal.replace(/SPECIFY.*/gm,'')
          metaVal = <>{metaVal}<span> {this.props.customDesc[this.props.index]}</span></>
        }
        metaDesc1 = metaVal
      }
    }
    let sortStyle = {}
    if(this.props.option.sortOverride){
      sortStyle = {
        order: this.props.option.sortOverride
      }
    }
    return(<>
      {selectObjects &&
        <div className={'select-row ' + selectedClass} style={sortStyle}>
          <div className="select-col">
          <label className={selectedClass}>{this.props.option.optionsLabel}</label>
          </div>
          <div className="select-col-lg">
          {selectObjects}
          {colorPicker}
          </div>
          <div className="select-col-sm">
          {smartPart.join(' ')}
          </div>
          <div className="select-col">
          {metaDesc1}
          </div>
        </div>
      }
      </>
    )
  }
}

export default SelectObject
